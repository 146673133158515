// @ts-nocheck
import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

const Share = () => {
  const url = 'https://www.lysjouhuis.co.za';

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10,
      }}
    >
      <FacebookShareButton url={url}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <PinterestShareButton url={url}>
        <PinterestIcon size={32} round={true} />
      </PinterestShareButton>
      <TwitterShareButton size={32} round={true} url={url}>
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <LinkedinShareButton size={32} round={true} url={url}>
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
      <WhatsappShareButton size={32} round={true} url={url}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <EmailShareButton size={32} round={true} url={url}>
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
    </div>
  );
};

export default Share;
