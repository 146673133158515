import { useCallback } from 'react';
import { FileInfo, Widget } from '@uploadcare/react-widget';
import { useSelector, useDispatch } from 'react-redux';
import {
  setFireEffect,
  setStage,
  setStagePhotoCount,
  setUser,
} from '../redux/session/sessionSlice';
import { RoomType, Stage } from '../enums';
import { StagePhotos } from '../interfaces/user';
import { RootState } from '../redux/store';

const translation = {
  uploading: 'Besig om te laai... Wag asseblief.',
  loadingInfo: 'Besig om inligting te laai...',
  errors: {
    default: 'Oeps... foutjie',
    baddata: 'Verkeerde waarde',
    size: 'Lêer te groot',
    upload: 'Kan nie oplaai nie',
    user: 'Oplaai gekanselleer',
    info: 'Kan nie inligting laai nie',
    image: "Slegs fotos word toegelaat",
    createGroup: 'Kan nie lêergroep skep nie',
    deleted: 'Lêer is uitgevee',
  },
  draghere: "Plaas 'n lêer hier",
  file: {
    one: '%1 lêer',
    other: '%1 lêers',
  },
  buttons: {
    cancel: 'Kanselleer',
    remove: 'Verwyder',
    choose: {
      files: {
        one: "Kies 'n lêer",
        other: 'Kies verskeie lêers',
      },
      images: {
        one: 'Neem foto',
        other: "Kies verskeie fotos",
      },
    },
  },
  dialog: {
    close: 'Maak toe',
    openMenu: 'Maak opsies oop',
    done: 'Klaar',
    showFiles: 'Vertoon lêers',
    tabs: {
      names: {
        'empty-pubkey': 'Welkom',
        preview: 'Voorskou',
        file: 'Lokale lêers',
        url: 'Direkte skakel',
        camera: 'Kamera',
        facebook: 'Facebook',
        dropbox: 'Dropbox',
        gdrive: 'Google Drive',
        gphotos: 'Google Photos',
        instagram: 'Instagram',
        vk: 'VK',
        evernote: 'Evernote',
        box: 'Box',
        onedrive: 'OneDrive',
        flickr: 'Flickr',
        huddle: 'Huddle',
        nft: 'NFT',
      },
      file: {
        drag: 'sleep en plaas<br>enige lêers',
        nodrop: 'Laai lêers op vanaf jou&nbsp;rekenaar',
        cloudsTip: 'Wolkstoor<br>en sosiale netwerke',
        or: 'of',
        button: "Kies 'n plaaslike lêer",
        also: 'of kies vanuit',
      },
      url: {
        title: 'Lêers vanaf die Web',
        line1: 'Gryp enige lêer van die web af.',
        line2: 'Voorsien slegs die skakel.',
        input: 'Plaas jou eie skakel hier...',
        button: 'Laai op',
      },
      camera: {
        camera: 'Kamera',
        title: 'Lêer vanaf webkamera',
        capture: "Neem 'n foto",
        mirror: 'Spieëlbeeld',
        startRecord: "Neem 'n video",
        stopRecord: 'Stop',
        cancelRecord: 'Kanselleer',
        retry: 'Versoek weer toestemming',
        pleaseAllow: {
          title: 'Laat asseblief toegang tot jou kamera toe',
          text:
            'Jy is gevra om kameratoegang vanaf hierdie werf toe te laat.<br>' +
            "Om fotos met jou kamera te neem moet jy hierdie versoek goedkeur.",
        },
        notFound: {
          title: 'Geen kamera word opgetel nie',
          text: 'Dit lyk of daar geen kamera aan hierdie toestel gekoppel is nie.',
        },
      },
      preview: {
        unknownName: 'Onbekend',
        change: 'Kanselleer',
        back: 'Terug',
        done: 'Voeg by',
        unknown: {
          title: "Besig om op te laai... wag asseblief vir 'n voorskou.",
          done: 'Slaan voorskou oor en aanvaar',
        },
        regular: {
          title: 'Voeg hierdie lêer by?',
          line1: 'Jy is op die punt om die lêer hierbo by te voeg.',
          line2: 'Bevestig asseblief.',
        },
        image: {
          title: 'Voeg die foto by?',
          change: 'Kanselleer',
        },
        crop: {
          title: 'Knip en voeg hierdie foto by',
          done: 'Klaar',
          free: 'vry',
        },
        video: {
          title: 'Voeg die video by?',
          change: 'Kanselleer',
        },
        error: {
          default: {
            title: 'Oops!',
            text: 'Iets het verkeerd geloop tydens die oplaai.',
            back: 'Probeer asseblief weer',
          },
          image: {
            title: 'Slegs fotolêers word aanvaar.',
            text: "Probeer asseblief weer met 'n ander lêer.",
            back: 'Neem foto',
          },
          size: {
            title: 'Die lêer wat jy gekies het is te groot.',
            text: "Probeer asseblief weer met 'n ander lêer.",
          },
          loadImage: {
            title: 'Oeps... foutjie',
            text: 'Kan nie foto oplaai nie',
          },
        },
        multiple: {
          title: 'Jy het %files% gekies.',
          question: 'Voeg by %files%?',
          tooManyFiles: 'Jy het te veel lêers gekies. %max% is maksimum.',
          tooFewFiles: 'Jy het %files% gekies. Ten minste %min% word vereis.',
          clear: 'Verwyder alles',
          done: 'Voeg by',
          file: {
            preview: 'Voorskou %file%',
            remove: 'Verwyder %file%',
          },
        },
      },
    },
    footer: {
      text: 'gedryf deur',
      link: 'uploadcare',
    },
  },
  serverErrors: {
    AccountBlockedError:
      'Administrateur se rekening is geblok. Kontak asseblief bystand.',
    AccountUnpaidError:
      'Administrateur se rekening is geblok. Kontak asseblief bystand.',
    AccountLimitsExceededError:
      'Administrateur se rekening het sy limiet bereik. Kontak asseblief bystand.',
    FileSizeLimitExceededError: 'Die lêer is te groot.',
    MultipartFileSizeLimitExceededError: 'Die lêer is te groot.',
    FileTypeForbiddenOnCurrentPlanError:
      'Oplaai van hierdie lêertipes word nie toegelaat nie.',
    DownloadFileSizeLimitExceededError: 'Afgelaaide lêer is te groot.',
  },
};

const Camera = () => {
  const session = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();

  const getRoomType = (stage: Stage) => {
    switch (stage) {
      case Stage.House23Captured:
        return RoomType.Front;
      case Stage.PhotoFrontPage:
        return RoomType.Kitchen;
      case Stage.PhotoKitchen:
        return RoomType.Bedrooms;
      case Stage.PhotoMainBedroom:
        return RoomType.Bathrooms;
      case Stage.PhotoMainBathroom:
        return RoomType.LivingRooms;
      case Stage.PhotoLivingRoom:
        return RoomType.BedroomsOther;
      case Stage.PhotoBedroom:
        return RoomType.BathroomsOther;
      case Stage.PhotoBathroom:
        return RoomType.Porch;
      case Stage.PhotoPorch:
        return RoomType.Balcony;
      case Stage.PhotoBalcony:
        return RoomType.Garden;
      default:
        return RoomType.NoPhoto;
    }
  };

  const getNextStage = (stage: Stage): any => {
    if (stage > Stage.PhotoBalcony) {
      return Stage.PhotoBalcony;
    } else if (stage === Stage.PhotoBalcony || stage === Stage.PhotoGarden) {
      return stage;
    } else {
      let photosToTake = 0;
      const roomType = getRoomType(stage);

      console.log('stage', stage);

      const answers = session.user?.house?.filter(
        (answer) => answer.roomType !== undefined && answer.roomType == roomType
      );
      if (roomType === RoomType.NoPhoto) {
      } else if (!answers || answers.length === 0) {
        photosToTake = 1;
      } else {
        photosToTake = Number(answers[0].answer ?? '0');
      }

      // Check how many was taken already
      const findThisStagePhotos: Array<StagePhotos> | undefined =
        session.user?.stagePhotos?.filter((item) => item.roomType === roomType);
      let stagePhoto: StagePhotos;

      if (findThisStagePhotos && findThisStagePhotos?.length > 0) {
        stagePhoto = findThisStagePhotos[0];
      } else {
        stagePhoto = {
          stage: stage,
          photos: [],
        };
      }

      if (stagePhoto.photos?.length === photosToTake) {
        return getNextStage(stage + 1);
      } else {
        return stage;
      }
    }
  };

  const onPictureSelect = useCallback(
    (fileInfo: FileInfo) => {
      // reset so the useEffect can fire
      dispatch(setFireEffect(false));
      const nextStage = session.stage + 1;
      const thisStage = session.stage;

      let photosToTake = 0;
      const roomType = getRoomType(thisStage);

      const answers = session.user?.house?.filter(
        (answer) => answer.roomType !== undefined && answer.roomType == roomType
      );
      if (roomType === RoomType.NoPhoto) {
      } else if (!answers || answers.length === 0) {
        photosToTake = 1;
      } else {
        photosToTake = Number(answers[0].answer ?? '0');
      }

      // Check how many was taken already
      const findThisStagePhotos: Array<StagePhotos> | undefined =
        session.user?.stagePhotos?.filter((item) => item.roomType === roomType);
      let stagePhoto: StagePhotos;

      if (findThisStagePhotos && findThisStagePhotos?.length > 0) {
        stagePhoto = findThisStagePhotos[0];
      } else {
        stagePhoto = {
          stage: session.stage,
          photos: [],
        };
      }

      const newPhotos: Array<string> = [...(stagePhoto.photos ?? [])];
      newPhotos.push(JSON.stringify(fileInfo));
      stagePhoto = { ...stagePhoto, photos: newPhotos, roomType: roomType };

      const newUserStagePhotos: Array<any> = [];
      if (
        !session.user?.stagePhotos ||
        session.user?.stagePhotos?.length === 0
      ) {
        newUserStagePhotos.push(stagePhoto);
      } else {
        let replaced = false;
        session.user?.stagePhotos.map((existingStagePhoto) => {
          if (existingStagePhoto.stage === stagePhoto.stage) {
            newUserStagePhotos.push(stagePhoto);
            replaced = true;
          } else {
            newUserStagePhotos.push(existingStagePhoto);
          }
        });
        if (!replaced) {
          newUserStagePhotos.push(stagePhoto);
        }
      }

      const stagePhotoCount = stagePhoto?.photos?.length ?? 0;

      if (
        thisStage === Stage.PhotoKitchen ||
        thisStage === Stage.PhotoMainBedroom
      ) {
        photosToTake = 1;
      }

      // if (
      //   thisStage === Stage.PhotoLivingRoom ||
      //   thisStage === Stage.PhotoBedroom
      // ) {
      //   // console.log('photosToTake bed/bath', photosToTake);

      //   // photosToTake = photosToTake - 1;

      //   // stagePhotoCount = stagePhotoCount - 1;
      //   dispatch(setStagePhotoCount(-1));
      // }

      dispatch(setStagePhotoCount(-1));

      const timeout = setTimeout(() => {
        clearTimeout(timeout);

        // if (
        //   photosToTake === stagePhotoCount + 1 &&
        //   (thisStage === Stage.PhotoBedroom ||
        //     thisStage === Stage.PhotoLivingRoom)
        // ) {
        //   dispatch(setStagePhotoCount(0));
        //   dispatch(setStage(getNextStage(nextStage)));
        // } else
        if (photosToTake === 0 && thisStage !== Stage.PhotoBalcony) {
          dispatch(setStagePhotoCount(0));
          dispatch(setStage(getNextStage(nextStage)));
        } else {
          console.log('here B');
          dispatch(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            setUser({ ...session.user, stagePhotos: newUserStagePhotos })
          );
          if (photosToTake === stagePhotoCount) {
            // if (thisStage === Stage.PhotoBedroom) {
            //   console.log('skip stage glitch for bedrooms');
            //   dispatch(setStage(nextStage + 1));
            // } else {

            dispatch(setStagePhotoCount(0));
            dispatch(setStage(getNextStage(nextStage)));

            // }
          } else {
            dispatch(setStagePhotoCount(stagePhotoCount ?? 0));
          }
        }
      }, 200);

      // dispatch(setFireEffect(true));
    },
    [session, dispatch]
  );

  // const temp = () => {
  //   onPictureSelect({
  //     name: ' ABC: ' + new Date().getMinutes().toString(),
  //     uuid: null,
  //     size: null,
  //     isStored: null,
  //     isImage: null,
  //     originalImageInfo: null,
  //     mimeType: null,
  //     originalUrl: null,
  //     cdnUrl: null,
  //     cdnUrlModifiers: null,
  //     sourceInfo: null,
  //   });
  // };

  return (
    <div>
      <Widget
        crop='16:9'
        publicKey='23229f6ebe2fca721386'
        tabs='camera'
        onChange={onPictureSelect}
        localeTranslations={translation}
        imagesOnly={true}
      />
      {/* <button onClick={temp}>Disabled camera {session.stage}</button> */}
    </div>
  );
};

export default Camera;
