/* eslint-disable lines-around-comment */
import config from '../../configs/chatbotConfig';
import MessageParser from '../../chatbot/MessageParser';
import ActionProvider from '../../chatbot/ActionProvider';
import { images } from '../../assets';
import Chatbot from 'react-chatbot-kit';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const Home = () => {
  const session = useSelector((state: RootState) => state.session);

  return (
    <main style={{ height: '100%' }}>
      <div className='row d-lg-none' style={{ height: '100%' }}>
        <div
          className='col-sm-12'
          id='thisChatContainer'
          style={{
            height: '100%',
            width: '100%',
            backgroundImage: 'url("/images/' + session.backgroundImage + '")',
            backgroundSize: '90%',
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: -75,
          }}
        >
          <Chatbot
            headerText='VirHuis met oom Callie'
            placeholderText='Antwoord hier...'
            //@ts-ignore
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}

            //@ts-ignore
            //   saveMessages={(messages, html) => { console.log(messages, html)}}
          />
        </div>
      </div>
      <div className='container'>
        <div className='row d-none d-md-flex'>
          <div className='row'>
            <div className='col-sm-10'></div>
            <div className='col-sm-2'>
              <img src={images.logo} alt='' className='logo' />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-5'>
              {/* <img src={getImage()} alt='' /> */}
            </div>
            <div className='col-sm-7'>
              <Chatbot
                headerText='VirHuis met oom Callie'
                placeholderText='Antwoord hier...'
                //@ts-ignore
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}

                //@ts-ignore
                //   saveMessages={(messages, html) => { console.log(messages, html)}}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
