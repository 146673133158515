import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setStage } from '../redux/session/sessionSlice';
import { Stage } from '../enums';

const Mandate = () => {
  const dispatch = useDispatch();

  const temp = useCallback(() => {
    dispatch(setStage(Stage.SignedMandate));
  }, [dispatch]);

  return (
    <div>
      <button onClick={temp}>Mandate widget to come here</button>
    </div>
  );
};

export default Mandate;
