import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Stage } from '../enums';
import {
  setBackgroundImage,
  setStage,
  setStagePhotoCount,
  setUser,
} from '../redux/session/sessionSlice';
import { RootState } from '../redux/store';

// import { StagePhotos } from '../interfaces/user';
import { DELAYS } from '../configs/delays';

const ActionProvider = ({ createChatBotMessage, setState, children }: any) => {
  const session = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();

  const getStageMessages = useCallback(
    (stage: Stage, photoCount: number): any[] => {
      const messages: any = [];

      switch (stage + 1) {
        case Stage.PhotoKitchen: {
          if (photoCount === 0) {
            messages.push(
              createChatBotMessage('Kom ons neem nou die kombuis af.', {
                delay: DELAYS.DELAY1,
              })
            );
            messages.push(
              createChatBotMessage(
                'Maak seker alle onnodige goedjies is weggepak en daar staan nie skottelgoed rond nie. Maak seker die kombuis vertoon so dat selfs jou skoonma dit sal aanvaar.',
                {
                  delay: DELAYS.DELAY2,
                  widget: 'camera',
                }
              )
            );
          } else {
            messages.push(
              createChatBotMessage(
                'Nou vir die ' + (photoCount + 1) + 'de' + ' kombuis.',
                {
                  delay: DELAYS.DELAY3,
                  widget: 'camera',
                }
              )
            );
          }
          break;
        }
        case Stage.PhotoMainBedroom: {
          messages.push(
            createChatBotMessage('Nou vir die hoof slaapkamer.', {
              delay: DELAYS.DELAY4,
            })
          );
          messages.push(
            createChatBotMessage(
              'Maak seker die bed is mooi opgemaak, en daar lê nie wasgoed en dinge rond nie.',
              {
                delay: DELAYS.DELAY5,
                widget: 'camera',
              }
            )
          );
          break;
        }
        case Stage.PhotoMainBathroom: {
          messages.push(
            createChatBotMessage('Nou vir die hoof badkamer.', {
              delay: DELAYS.DELAY6,
            })
          );
          messages.push(
            createChatBotMessage(
              'As jy kan, pak die seep botteltjies en sponsies weg. Hang die handdoeke netjies en steek weg die wasgoedmandjie. Laat sak asseblief die toiletsitplek en bêre die skaal vir nou.',
              {
                delay: DELAYS.DELAY7,
                widget: 'camera',
              }
            )
          );
          break;
        }
        case Stage.PhotoLivingRoom: {
          if (photoCount === 0) {
            messages.push(
              createChatBotMessage('Nou vir die 1ste leefkamer.', {
                delay: DELAYS.DELAY8,
              })
            );
            messages.push(
              createChatBotMessage(
                'Maak weereens seker alles is netjies en opgeruim.',
                {
                  delay: DELAYS.DELAY9,
                  widget: 'camera',
                }
              )
            );
          } else {
            messages.push(
              createChatBotMessage(
                'Nou vir die ' + (photoCount + 1) + 'de' + ' leefkamer.',
                {
                  delay: DELAYS.DELAY10,
                  widget: 'camera',
                }
              )
            );
          }
          break;
        }
        case Stage.PhotoBedroom: {
          if (photoCount === 0) {
            messages.push(
              createChatBotMessage(
                'Kom ons neem nou die ander slaapkamers af. Gaan na die 2de slaapkamer.',
                { delay: DELAYS.DELAY11 }
              )
            );
            messages.push(
              createChatBotMessage(
                'Maak weereens seker alles is netjies en opgeruim.',
                {
                  delay: DELAYS.DELAY12,
                  widget: 'camera',
                }
              )
            );
          } else {
            messages.push(
              createChatBotMessage(
                'Gaan na die ' + (photoCount + 1) + 'de' + ' slaapkamer.',
                {
                  delay: DELAYS.DELAY13,
                  widget: 'camera',
                }
              )
            );
          }
          break;
        }
        case Stage.PhotoBathroom: {
          if (photoCount === 0) {
            messages.push(
              createChatBotMessage(
                'Kom ons neem nou die ander badkamers af. Gaan na die 2de badkamer.',
                { delay: DELAYS.DELAY14 }
              )
            );
            messages.push(
              createChatBotMessage(
                'Maak weereens seker alles is weggepak, netjies en opgeruim.',
                {
                  delay: DELAYS.DELAY15,
                  widget: 'camera',
                }
              )
            );
          } else {
            messages.push(
              createChatBotMessage(
                'Gaan na die ' + (photoCount + 1) + 'de' + ' badkamer.',
                {
                  delay: DELAYS.DELAY16,
                  widget: 'camera',
                }
              )
            );
          }
          break;
        }
        case Stage.PhotoPorch: {
          if (photoCount === 0) {
            messages.push(
              createChatBotMessage(
                'Kom ons neem nou die stoepe af. Gaan na die 1ste stoep toe.',
                { delay: DELAYS.DELAY17 }
              )
            );
            messages.push(
              createChatBotMessage(
                'Pak alles mooi reg. Probeer die uitsig en tuin ook in kry as jy kan.',
                {
                  delay: DELAYS.DELAY18,
                  widget: 'camera',
                }
              )
            );
          } else {
            messages.push(
              createChatBotMessage(
                'Gaan na die ' + (photoCount + 1) + 'de' + ' stoep toe.',
                {
                  delay: DELAYS.DELAY19,
                  widget: 'camera',
                }
              )
            );
          }
          break;
        }
        case Stage.PhotoBalcony: {
          if (photoCount === 0) {
            messages.push(
              createChatBotMessage(
                'Kom ons neem nou die balkonne af, kry die tuin ook in as jy kan. Gaan na die 1ste balkon toe.',
                { delay: DELAYS.DELAY20 }
              )
            );
            messages.push(
              createChatBotMessage(
                'Pak alles mooi reg. Probeer die uitsig en tuin ook in kry as jy kan.',
                {
                  delay: DELAYS.DELAY21,
                  widget: 'camera',
                }
              )
            );
          } else {
            messages.push(
              createChatBotMessage(
                'Gaan na die ' + (photoCount + 1) + 'de' + ' balkon toe.',
                {
                  delay: DELAYS.DELAY22,
                  widget: 'camera',
                }
              )
            );
          }
          break;
        }
        case Stage.PhotoGarden: {
          if (photoCount === 0) {
            messages.push(
              createChatBotMessage('Kom ons neem nou die tuin af.', {
                delay: DELAYS.DELAY23,
                widget: 'camera',
              })
            );
          } else {
            messages.push(
              createChatBotMessage('Wil jy nog enige ander fotos neem?', {
                delay: DELAYS.DELAY24,
              })
            );
          }
          break;
        }
        case Stage.SignedMandate: {
          console.log('from here.....');
          if (photoCount === 1000) {
            dispatch(setStagePhotoCount(0));
            messages.push(
              createChatBotMessage(
                'Baie Dankie, Alle dokumente sal na jou toe ge-epos word. Een van ons konsultante sal ook dan met jou in verbinding tree.',
                {
                  delay: DELAYS.DELAY25,
                }
              )
            );
            messages.push(
              createChatBotMessage(
                'Sal jy graag die Toep met vriende of familie wil deel?',
                {
                  delay: DELAYS.DELAY26,
                }
              )
            );
          }
          break;
        }
      }

      return messages;
    },
    [createChatBotMessage, dispatch, session.user]
  );

  const handlePopi = () => {
    setTimeout(() => {
      dispatch(setBackgroundImage('char2.png'));
    }, DELAYS.DELAY27);

    dispatch(setStage(Stage.Popi));
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage('Hallo ' + session.user?.name, {
          delay: DELAYS.DELAY27,
        }),
        createChatBotMessage('My naam is Tannie Popi!', {
          delay: DELAYS.DELAY28,
        }),
        createChatBotMessage(
          'Die POPIA wet sê ons mag nie met jou inligting werk sonder jou toestemming nie.',
          { delay: DELAYS.DELAY29 }
        ),
        createChatBotMessage(
          'Mag ons maar voortgaan en jou inligting gebruik om jou te help om jou huis te verkoop?',
          { delay: DELAYS.DELAY30 }
        ),
      ],
    }));
  };

  const handlePopiApproved = () => {
    dispatch(setBackgroundImage('char3.png'));
    dispatch(setStage(Stage.PopiApproved));
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage(
          'Dankie ' +
            session.user?.name +
            ' dat jy vir Tannie Popi gehelp het.',
          { delay: DELAYS.DELAY31, widget: 'CharCasual' }
        ),
        createChatBotMessage(
          'Net ‘n paar vrae oor jou persoonlike inligting,',
          {
            delay: DELAYS.DELAY98,
          }
        ),
        createChatBotMessage('Wat is jou van? ', {
          delay: DELAYS.DELAY32,
        }),
      ],
    }));
  };

  const handleNameCaptured = (value: string) => {
    dispatch(
      setUser({
        ...(session.user ?? { name: '', defects: [], house: [] }),
        name: value,
      })
    );
    dispatch(setStage(Stage.NameCaptured));
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage(
          'Bly te kenne ' + value + ', kom ek help jou om jou huis te lys',
          { delay: DELAYS.DELAY33 }
        ),
        createChatBotMessage(
          'Ek moet jou net eers gou voorstel aan Tannie Popi.',
          { delay: DELAYS.DELAY34, widget: 'CharPopi' }
        ),
      ],
    }));
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      handlePopi();
    }, DELAYS.DELAY35);
  };

  const handleSurnameCaptured = (value: string) => {
    dispatch(
      setUser({
        ...(session.user ?? { name: '', defects: [], house: [] }),
        surname: value,
      })
    );
    dispatch(setStage(Stage.SurnameCaptured));
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage('Wat is jou ID Nommer?', {
          delay: DELAYS.DELAY36,
        }),
      ],
    }));
  };

  const handleIDCaptured = (value: string) => {
    dispatch(
      setUser({
        ...(session.user ?? { name: '', defects: [], house: [] }),
        idnumber: value,
      })
    );
    dispatch(setStage(Stage.IDCaptured));
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage('Wat is jou selfoonnommer?', {
          delay: DELAYS.DELAY37,
        }),
      ],
    }));
  };

  const handleCellphoneCaptured = (value: string) => {
    dispatch(
      setUser({
        ...(session.user ?? { name: '', defects: [], house: [] }),
        cellphone: value,
      })
    );
    dispatch(setStage(Stage.CellphoneCaptured));
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage('en, wat is jou e-pos adres?', {
          delay: DELAYS.DELAY38,
        }),
      ],
    }));
  };

  const handleEmailCaptured = (value: string) => {
    dispatch(
      setUser({
        ...(session.user ?? { name: '', defects: [], house: [] }),
        email: value,
      })
    );
    dispatch(setStage(Stage.EmailCaptured));
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage('Baie dankie', {
          delay: DELAYS.DELAY39,
        }),
        createChatBotMessage(
          'Wat is die adres van die huis wat julle wil verkoop?',
          {
            delay: DELAYS.DELAY40,
          }
        ),
      ],
    }));
  };

  const handleAddressCaptured = (value: string) => {
    dispatch(
      setUser({
        ...(session.user ?? { name: '', defects: [], house: [] }),
        address: value,
      })
    );
    dispatch(setStage(Stage.AddressCaptured));
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage(
          'Vir hoeveel wil jy hê moet ons die huis in die mark sit?',
          {
            delay: DELAYS.DELAY41,
          }
        ),
        createChatBotMessage(
          'Let wel ons kommissie is slegs 2% !!! met ‘n minimum van R 20 000.00',
          {
            delay: DELAYS.DELAY42,
          }
        ),
      ],
    }));
  };

  const handlePriceCaptured = (value: string) => {
    const price = Number(
      value.replace(' ', '').replace(',', '').replace('R', '')
    );
    dispatch(
      setUser({
        ...(session.user ?? { name: '', defects: [], house: [] }),
        price: price.toFixed(2),
      })
    );
    dispatch(setStage(Stage.PriceCaptured));
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage(
          'Let wel dat as jy ‘n gratis bankwaardasie soek, kan ons dit reël. Ons gaan egter ‘n alleenmandaat hiervoor benodig. ',
          {
            delay: DELAYS.DELAY43,
          }
        ),
        createChatBotMessage(
          'Sal jy hierin belangstel dan maak ons met jou kontak?',
          {
            delay: DELAYS.DELAY44,
          }
        ),
      ],
    }));
  };

  const handleBankValuationCaptured = (value: string) => {
    const price = Number(session.user?.price ?? '0');
    const commission = price * 0.02 < 20000 ? 20000 : price * 0.02;
    dispatch(
      setUser({
        ...(session.user ?? { name: '', defects: [], house: [] }),
        bankvaluation: value,
      })
    );
    dispatch(setStage(Stage.BankValuation));

    const timeout = setTimeout(
      () => {
        clearTimeout(timeout);
        dispatch(setBackgroundImage('char4.png'));
      },
      DELAYS.DELAY46 +
        DELAYS.DELAY47 +
        DELAYS.DELAY48 +
        DELAYS.DELAY49 +
        DELAYS.DELAY50 +
        DELAYS.DELAY51 +
        DELAYS.DELAY52 -
        1
    );

    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage(
          value.trim().toUpperCase() === 'YES' ||
            value.trim().toUpperCase() === 'JA'
            ? 'Reg, een van ons konsultante sal jou hieroor skakel'
            : 'Geen probleem, kom ons gaan aan',
          {
            delay: DELAYS.DELAY46,
          }
        ),
        createChatBotMessage(
          'Na gelang van jou prys sal die somme dan soos volg uitwerk,',
          {
            delay: DELAYS.DELAY47 + DELAYS.DELAY46,
          }
        ),
        createChatBotMessage('Die lys prys is R ' + price.toFixed(2), {
          delay: DELAYS.DELAY48 + DELAYS.DELAY47 + DELAYS.DELAY46,
        }),
        createChatBotMessage('Ons kommissie is R ' + commission.toFixed(2), {
          delay:
            DELAYS.DELAY49 + DELAYS.DELAY48 + DELAYS.DELAY47 + DELAYS.DELAY46,
        }),
        createChatBotMessage(
          'Die prys wat jy dan in jou sak kry is R' +
            (price - commission).toFixed(2),
          {
            delay:
              DELAYS.DELAY50 +
              DELAYS.DELAY49 +
              DELAYS.DELAY48 +
              DELAYS.DELAY47 +
              DELAYS.DELAY46,
          }
        ),
        createChatBotMessage('Reg, ons is amper klaar met die papierwerk.', {
          delay:
            DELAYS.DELAY51 +
            DELAYS.DELAY50 +
            DELAYS.DELAY49 +
            DELAYS.DELAY48 +
            DELAYS.DELAY47 +
            DELAYS.DELAY46,
        }),
        createChatBotMessage(
          'Daar is nog een dokument oor wat ons moet afhandel.',
          {
            delay:
              DELAYS.DELAY52 +
              DELAYS.DELAY51 +
              DELAYS.DELAY50 +
              DELAYS.DELAY49 +
              DELAYS.DELAY48 +
              DELAYS.DELAY47 +
              DELAYS.DELAY46,
          }
        ),
        createChatBotMessage(
          'Dit gaan oor die defekte aan jou huis. Die nuwe wet se ons moet dit opneem en vir kopers wys wat jou huis wil koop.',
          {
            delay:
              DELAYS.DELAY53 +
              DELAYS.DELAY52 +
              DELAYS.DELAY51 +
              DELAYS.DELAY50 +
              DELAYS.DELAY49 +
              DELAYS.DELAY48 +
              DELAYS.DELAY47 +
              DELAYS.DELAY46,
            widget: 'CharQuality',
          }
        ),
        createChatBotMessage(
          'Antwoord asseblief die volgende vrae deur te sê Ja, Nee of Nvt.',
          {
            delay:
              DELAYS.DELAY54 +
              DELAYS.DELAY53 +
              DELAYS.DELAY52 +
              DELAYS.DELAY51 +
              DELAYS.DELAY50 +
              DELAYS.DELAY49 +
              DELAYS.DELAY48 +
              DELAYS.DELAY47 +
              DELAYS.DELAY46,
          }
        ),
        createChatBotMessage(session.user?.defects[0].defectDescription, {
          delay:
            DELAYS.DELAY55 +
            DELAYS.DELAY54 +
            DELAYS.DELAY53 +
            DELAYS.DELAY52 +
            DELAYS.DELAY51 +
            DELAYS.DELAY50 +
            DELAYS.DELAY49 +
            DELAYS.DELAY48 +
            DELAYS.DELAY47 +
            DELAYS.DELAY46,
        }),
      ],
    }));
  };

  const handleDefectCaptured = (defectIndex: number, value: string) => {
    const newDefects = [...(session.user?.defects ?? [])];
    let moveAlong = true;
    let nextMessage = '';

    if ((newDefects[defectIndex - 1].defectYesNo ?? '') === '') {
      if (
        !value.toUpperCase().startsWith('JA') &&
        !value.toUpperCase().startsWith('YES') &&
        !value.toUpperCase().startsWith('NO') &&
        !value.toUpperCase().startsWith('NEE') &&
        !value.toUpperCase().startsWith('N/A') &&
        !value.toUpperCase().startsWith('NA') &&
        !value.toUpperCase().startsWith('NVT')
      ) {
        setState((prev: any) => ({
          ...prev,
          messages: [
            ...prev.messages,
            createChatBotMessage(
              'Ons verstaan nie jou antwoord nie. Tik asb slegs JA, NEE of NVT.',
              {
                delay: DELAYS.DELAY56,
              }
            ),
          ],
        }));

        return;
      }
      newDefects[defectIndex - 1] = {
        ...newDefects[defectIndex - 1],
        defectYesNo: value,
      };

      if (
        value.toUpperCase().trim().indexOf('JA') !== -1 ||
        value.toUpperCase().trim().indexOf('YES') !== -1
      ) {
        moveAlong = false;
        nextMessage = 'Beskryf asb die defek';
      }
    } else if ((newDefects[defectIndex - 1].defectExplained ?? '') === '') {
      newDefects[defectIndex - 1] = {
        ...newDefects[defectIndex - 1],
        defectExplained: value,
      };
    }

    dispatch(
      setUser({
        ...(session.user ?? { name: '', defects: [], house: [] }),
        defects: newDefects,
      })
    );

    switch (defectIndex) {
      case 1:
        if (moveAlong) {
          dispatch(setStage(Stage.Defect1Captured));
          nextMessage = session.user?.defects[1].defectDescription ?? '';
        }
        break;
      case 2:
        if (moveAlong) {
          dispatch(setStage(Stage.Defect2Captured));
          nextMessage = session.user?.defects[2].defectDescription ?? '';
        }
        break;
      case 3:
        if (moveAlong) {
          dispatch(setStage(Stage.Defect3Captured));
          nextMessage = session.user?.defects[3].defectDescription ?? '';
        }
        break;
      case 4:
        if (moveAlong) {
          dispatch(setStage(Stage.Defect4Captured));
          nextMessage = session.user?.defects[4].defectDescription ?? '';
        }
        break;
      case 5:
        if (moveAlong) {
          dispatch(setStage(Stage.Defect5Captured));
          nextMessage = session.user?.defects[5].defectDescription ?? '';
        }
        break;
      case 6:
        if (moveAlong) {
          dispatch(setStage(Stage.Defect6Captured));
          nextMessage = session.user?.defects[6].defectDescription ?? '';
        }
        break;
      case 7:
        if (moveAlong) {
          dispatch(setStage(Stage.Defect7Captured));
          nextMessage = session.user?.defects[7].defectDescription ?? '';
        }
        break;
      case 8:
        if (moveAlong) {
          dispatch(setStage(Stage.Defect8Captured));
          nextMessage = session.user?.defects[8].defectDescription ?? '';
        }
        break;
      case 9:
        if (moveAlong) {
          dispatch(setStage(Stage.Defect9Captured));
          nextMessage = session.user?.defects[9].defectDescription ?? '';
        }
        break;
      case 10:
        if (moveAlong) {
          dispatch(setStage(Stage.Defect10Captured));
          nextMessage = session.user?.defects[10].defectDescription ?? '';
        }
        break;
      case 11:
        if (moveAlong) {
          dispatch(setStage(Stage.Defect11Captured));

          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            dispatch(setBackgroundImage('char5.png'));
          }, DELAYS.DELAY57);

          setState((prev: any) => ({
            ...prev,
            messages: [
              ...prev.messages,
              createChatBotMessage('Dankie.', {
                delay: DELAYS.DELAY58,
              }),
              createChatBotMessage('Dit was nou ‘n mond vol. ', {
                delay: DELAYS.DELAY59,
              }),
              createChatBotMessage(
                'Nou kan ons uiteindelik by die huis self uitkom. ',
                {
                  delay: DELAYS.DELAY60,
                  widget: 'CharNotes',
                }
              ),
              createChatBotMessage('Watse tipe eiendom is dit,', {
                delay: DELAYS.DELAY61,
              }),
              createChatBotMessage(session.user?.house[0].question, {
                delay: DELAYS.DELAY62,
              }),
            ],
          }));

          return;
        }
        break;
    }

    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage(nextMessage, {
          delay: DELAYS.DELAY63,
        }),
      ],
    }));
  };

  const handleQuestionCaptured = (questionIndex: number, value: string) => {
    const newQuestions = [...(session.user?.house ?? [])];
    const moveAlong = true;
    let nextMessage = '';

    if ((newQuestions[questionIndex - 1].answer ?? '') === '') {
      newQuestions[questionIndex - 1] = {
        ...newQuestions[questionIndex - 1],
        answer: value,
      };
    } else {
      console.log('nie leeg nie??', newQuestions[questionIndex - 1]);
    }

    dispatch(
      setUser({
        ...(session.user ?? { name: '', defects: [], house: [] }),
        house: newQuestions,
      })
    );

    switch (questionIndex) {
      case 1:
        if (moveAlong) {
          dispatch(setStage(Stage.House1Captured));
          nextMessage = session.user?.house[1].question ?? '';
        }
        break;
      case 2:
        if (moveAlong) {
          dispatch(setStage(Stage.House2Captured));
          nextMessage = session.user?.house[2].question ?? '';
        }
        break;
      case 3:
        if (moveAlong) {
          dispatch(setStage(Stage.House3Captured));
          nextMessage = session.user?.house[3].question ?? '';
        }
        break;
      case 4:
        if (moveAlong) {
          dispatch(setStage(Stage.House4Captured));
          nextMessage = session.user?.house[4].question ?? '';
        }
        break;
      case 5:
        if (moveAlong) {
          dispatch(setStage(Stage.House5Captured));
          nextMessage = session.user?.house[5].question ?? '';
        }
        break;
      case 6:
        if (moveAlong) {
          dispatch(setStage(Stage.House6Captured));
          nextMessage = session.user?.house[6].question ?? '';
        }
        break;
      case 7:
        if (moveAlong) {
          dispatch(setStage(Stage.House7Captured));
          nextMessage = session.user?.house[7].question ?? '';
        }
        break;
      case 8:
        if (moveAlong) {
          dispatch(setStage(Stage.House8Captured));
          nextMessage = session.user?.house[8].question ?? '';
        }
        break;
      case 9:
        if (moveAlong) {
          dispatch(setStage(Stage.House9Captured));
          nextMessage = session.user?.house[9].question ?? '';
        }
        break;
      case 10:
        if (moveAlong) {
          dispatch(setStage(Stage.House10Captured));
          nextMessage = session.user?.house[10].question ?? '';
        }
        break;
      case 11:
        if (moveAlong) {
          dispatch(setStage(Stage.House11Captured));
          nextMessage = session.user?.house[11].question ?? '';
        }
        break;
      case 12:
        if (moveAlong) {
          dispatch(setStage(Stage.House12Captured));
          nextMessage = session.user?.house[12].question ?? '';
        }
        break;
      case 13:
        if (moveAlong) {
          dispatch(setStage(Stage.House13Captured));
          nextMessage = session.user?.house[13].question ?? '';
        }
        break;
      case 14:
        if (moveAlong) {
          dispatch(setStage(Stage.House14Captured));
          nextMessage = session.user?.house[14].question ?? '';
        }
        break;
      case 15:
        if (moveAlong) {
          dispatch(setStage(Stage.House15Captured));
          nextMessage = session.user?.house[15].question ?? '';
        }
        break;
      case 16:
        if (moveAlong) {
          dispatch(setStage(Stage.House16Captured));
          nextMessage = session.user?.house[16].question ?? '';
        }
        break;
      case 17:
        if (moveAlong) {
          dispatch(setStage(Stage.House17Captured));
          nextMessage = session.user?.house[17].question ?? '';
        }
        break;
      case 18:
        if (moveAlong) {
          dispatch(setStage(Stage.House18Captured));
          nextMessage = session.user?.house[18].question ?? '';
        }
        break;
      case 19:
        if (moveAlong) {
          dispatch(setStage(Stage.House19Captured));
          nextMessage = session.user?.house[19].question ?? '';
        }
        break;
      case 20:
        if (moveAlong) {
          dispatch(setStage(Stage.House20Captured));
          nextMessage = session.user?.house[20].question ?? '';
        }
        break;
      case 21:
        if (moveAlong) {
          dispatch(setStage(Stage.House21Captured));
          nextMessage = session.user?.house[21].question ?? '';
        }
        break;
      case 22:
        if (moveAlong) {
          dispatch(setStage(Stage.House22Captured));
          nextMessage = session.user?.house[22].question ?? '';
        }
        break;
      case 23:
        if (moveAlong) {
          dispatch(setStage(Stage.House23Captured));

          const timeout = setTimeout(
            () => {
              clearTimeout(timeout);
              dispatch(setBackgroundImage('char6.png'));
            },
            DELAYS.DELAY65 + DELAYS.DELAY66 - 1
          );

          setState((prev: any) => ({
            ...prev,
            messages: [
              ...prev.messages,
              createChatBotMessage(
                'Ons het nou alles bespreek rakende die huis',
                {
                  delay: DELAYS.DELAY65,
                }
              ),
              createChatBotMessage('Dit is nou tyd vir fotos neem.', {
                delay: DELAYS.DELAY66,
                widget: 'CharPhotos',
              }),
              createChatBotMessage(
                'Om goeie gehalte fotos te neem maak asseblief seker van die volgende,',
                {
                  delay: DELAYS.DELAY67,
                }
              ),
              createChatBotMessage('Stel jou kamera op 16x9 resolusie.', {
                delay: DELAYS.DELAY68,
              }),
              createChatBotMessage('Neem slegs landskap fotos.', {
                delay: DELAYS.DELAY69,
              }),
              createChatBotMessage(
                'Neem op 0.5X of 0.6X as jou kamera dit toelaat.',
                {
                  delay: DELAYS.DELAY70,
                }
              ),
              createChatBotMessage(
                'Maak seker die kamers is netjies en skoon.',
                {
                  delay: DELAYS.DELAY71,
                }
              ),
              createChatBotMessage(
                'Maak al die gordyne oop en skakel al die ligte aan.',
                {
                  delay: DELAYS.DELAY72,
                }
              ),
              createChatBotMessage('Reg, kom ons begin neem', {
                delay: DELAYS.DELAY73,
              }),
              createChatBotMessage(
                'Ons kort altyd ‘n goeie gehalte voorblad foto om ons kopers te beïndruk.',
                {
                  delay: DELAYS.DELAY74,
                }
              ),
              createChatBotMessage(
                'Gaan kry ‘n mooi aansig van die huis wat jy dink die mees aanloklikste sal vertoon.',
                {
                  delay: DELAYS.DELAY75,
                  widget: 'camera',
                }
              ),
            ],
          }));

          return;
        }
        break;
    }

    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage(nextMessage, {
          delay: 0,
        }),
      ],
    }));
  };

  const handlePopiDeclined = () => {
    dispatch(setStage(Stage.PopiDeclined));
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage(
          'Kom gesels asseblief weer met ons as jy van plan verander het. Ons mag nie voortgaan sonder jou toestemming nie.',
          { delay: DELAYS.DELAY76 }
        ),
      ],
    }));
  };

  const handleListInEnglish = (value: string) => {
    setTimeout(
      () => {
        dispatch(setBackgroundImage('char7.png'));
      },
      DELAYS.DELAY77 + DELAYS.DELAY78 - 1
    );
    const newMessages: any[] = [
      createChatBotMessage('En dit is nou als!', { delay: DELAYS.DELAY77 }),
      createChatBotMessage(
        'Baie dankie vir jou hulp met die lys van jou huis.',
        { delay: DELAYS.DELAY78, widget: 'CharJudicial' }
      ),
      createChatBotMessage(
        'Gaan gou deur die mandaat en teken as jy gelukkig is.',
        { delay: DELAYS.DELAY79, widget: 'signature' }
      ),
    ];

    setState((prev: any) => ({
      ...prev,
      messages: [...prev.messages, ...newMessages],
    }));

    dispatch(
      setUser({
        ...(session.user ?? { name: '', defects: [], house: [] }),
        listInEnglish: value,
      })
    );
    dispatch(setStage(Stage.SignMandate));
  };

  const handleNotUnderstood = () => {
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage('Jammer, ek verstaan jou nie....', {
          delay: DELAYS.DELAY80,
        }),
      ],
    }));
  };

  const handleDog = () => {
    const botMessage = createChatBotMessage(
      "Here's a nice dog picture for you!",
      {
        widget: 'dogPicture',
      }
    );

    setState((prev: any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleCamera = (message: string) => {
    const botMessage = createChatBotMessage(message, {
      delay: DELAYS.DELAY81,
      widget: 'camera',
    });

    setState((prev: any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleGardenPhotos = (more: boolean) => {
    if (more) {
      setState((prev: any) => ({
        ...prev,
        messages: [
          ...prev.messages,
          createChatBotMessage('Neem nou die volgende foto.', {
            delay: DELAYS.DELAY82,
            widget: 'camera',
          }),
        ],
      }));
    } else {
      dispatch(setStage(Stage.ListInEnglish));
      setState((prev: any) => ({
        ...prev,
        messages: [
          ...prev.messages,
          createChatBotMessage('Dit was nou vet pret.', {
            delay: DELAYS.DELAY83,
          }),
          createChatBotMessage('Ek wil dan nog laastens gou by jou hoor', {
            delay: DELAYS.DELAY84,
          }),
          createChatBotMessage(
            'Alhoewel ons in Afrikaans lys, beteken dit dat ons dalk Engelse kopers kan verloor met die bemarking.',
            {
              delay: DELAYS.DELAY85,
            }
          ),
          createChatBotMessage(
            'Wil jy hê ons moet in Engels bemark om dit te vermy, of slegs in Afrikaans?',
            { delay: DELAYS.DELAY86 }
          ),
        ],
      }));
    }
  };

  const handleShare = (share: boolean) => {
    if (share) {
      setState((prev: any) => ({
        ...prev,
        messages: [
          ...prev.messages,
          createChatBotMessage(
            'Baie dankie ons waardeer jou ondersteuning! As jy klaar gedeel het is ons klaar. Lekker dag verder.',
            {
              delay: DELAYS.DELAY87,
              widget: 'share',
            }
          ),
        ],
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        messages: [
          ...prev.messages,
          createChatBotMessage(
            'Baie dankie, ons is nou klaar. Lekker dag verder.',
            {
              delay: DELAYS.DELAY88,
            }
          ),
        ],
      }));
    }
  };

  const handleCustomMessage = (message: boolean) => {
    setState((prev: any) => ({
      ...prev,
      messages: [
        ...prev.messages,
        createChatBotMessage(message, {
          delay: DELAYS.DELAY89,
        }),
      ],
    }));
  };

  useEffect(() => {
    console.log('stage', session.stage);
    if (
      (session.stagePhotoCount ?? 0) >= 0 &&
      (session.stagePhotoCount ?? 0) !== 1000 &&
      session.stage >= Stage.PhotoFrontPage
    ) {
      const messages: any = getStageMessages(
        session.stage,
        session.stagePhotoCount ?? 0
      );

      setState((prev: any) => ({
        ...prev,
        messages: [...prev.messages, ...messages],
      }));
    } else if (session.stage === Stage.SignedMandate) {
      console.log('session.stagePhotoCount', session.stagePhotoCount);
      const messages: any = getStageMessages(Stage.SignedMandate - 1, 1000);
      console.log('messages B', messages);

      setState((prev: any) => ({
        ...prev,
        messages: [...prev.messages, ...messages],
      }));
    }
  }, [session.stage, session.stagePhotoCount]);

  return (
    <div>
      {React.Children.map(children, (child: any) => {
        return React.cloneElement(child, {
          actions: {
            // handleHello,
            handleDog,
            handleCamera,
            handlePopi,
            handlePopiApproved,
            handlePopiDeclined,
            handleNameCaptured,
            handleSurnameCaptured,
            handleIDCaptured,
            handleEmailCaptured,
            handleNotUnderstood,
            handleAddressCaptured,
            handlePriceCaptured,
            handleBankValuationCaptured,
            handleDefectCaptured,
            handleQuestionCaptured,
            handleGardenPhotos,
            handleListInEnglish,
            handleShare,
            handleCustomMessage,
            handleCellphoneCaptured,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
