import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SignatureCanvas from 'react-signature-canvas';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStage, setStagePhotoCount } from '../redux/session/sessionSlice';
import { RootState } from '../redux/store';
import { Stage } from '../enums';
import { User } from '../interfaces/user';
import { Flex } from '../interfaces/flex';
import { flexInsert, flexUpdate } from '../api/flex';
import { Spinner } from 'react-bootstrap';
import { Config } from '../Config';

const Signature = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [mandateModalVisible, setMandateModalVisible] = useState(false);
  const sigPad = useRef<any>();
  const dispatch = useDispatch();
  const session = useSelector((state: RootState) => state.session);
  const [savedUser, setSavedUser] = useState('');
  const [loading, setLoading] = useState(false);
  const [mandateUrl, setMandateUrl] = useState(
    'https://node.lysjouhuis.co.za/api/flex/create-mandate/640887ec98ffe64bbbb9e11d'
  );

  const onSignTap = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onViewTap = useCallback(() => {
    // setMandateModalVisible(true);
    // return;
    setLoading(true);
    const newUser: User = {
      ...session.user,
      name: session.user?.name ?? '',
      defects: session.user?.defects ?? [],
      house: session.user?.house ?? [],
    };
    const flex: Flex = {
      collection: 'users',
      model: newUser,
    };
    flexInsert(flex).then((data) => {
      setLoading(false);
      if (data.status === 200) {
        setSavedUser(data.data.insertedId);
        setMandateUrl(
          `${Config.API_SERVER}/flex/create-mandate/${data.data.insertedId}`
        );
        setMandateModalVisible(true);
      } else {
        alert('Something has gone wrong!!');
      }
    });
  }, [session.user]);

  const onSave = useCallback(() => {
    //@ts-ignore
    const imageUri: string = sigPad?.current
      ?.getTrimmedCanvas()
      .toDataURL('image/png');
    if (
      imageUri ===
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC'
    ) {
      alert('Teken asb met jou finger/muis op die skerm!');

      return;
    } else {
      const newModel: User = {
        ...session.user,
        name: session.user?.name ?? '',
        defects: session.user?.defects ?? [],
        house: session.user?.house ?? [],
        signature: imageUri,
        createdAt: new Date(),
      };
      const flex: Flex = {
        collection: 'users',
        model: newModel,
        _id: savedUser ?? '',
      };
      flexUpdate(flex)
        .then((data) => {
          if (data.status === 200) {
            dispatch(setStagePhotoCount(1000));
            dispatch(setStage(Stage.SignedMandate));
            setModalVisible(false);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [dispatch, savedUser, session]);

  return (
    <>
      {mandateModalVisible ? (
        <div
          className='modal show'
          style={{
            display: 'block',
            position: 'absolute',
            zIndex: 100000,
          }}
        >
          <Modal.Dialog>
            <Modal.Header>
              <Modal.Title>Mandate</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ height: '100%' }}>
              <iframe
                src={mandateUrl}
                width='100%'
                height={window.innerHeight - 180}
              ></iframe>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => {
                  //@ts-ignore
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm('Is jy seker jy wil die proses kanselleer?')) {
                    setMandateModalVisible(false);
                  }
                }}
              >
                Kanselleer
              </Button>
              <Button
                variant='secondary'
                onClick={() => {
                  setMandateModalVisible(false);
                  onSignTap();
                }}
              >
                Teken Mandaat
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      ) : null}
      <div className='signatureCanvas'>
        {modalVisible ? (
          <div
            className='modal show'
            style={{ display: 'block', position: 'absolute', zIndex: 1000 }}
          >
            <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>Mandate Signature</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <SignatureCanvas
                  ref={(ref) => {
                    sigPad.current = ref;
                  }}
                  penColor='black'
                  canvasProps={{
                    color: 'white',
                    width: 500,
                    height: 300,
                    className: 'sigCanvas',
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='secondary'
                  onClick={() => setModalVisible(false)}
                >
                  Kanselleer
                </Button>
                <Button variant='primary' onClick={onSave}>
                  Ek aanvaar, gaan voort
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        ) : (
          <>
            {!loading ? (
              <div className='row'>
                <div className='col-sm-3'>
                  <Button variant='secondary' onClick={onViewTap}>
                    Sien mandaat
                  </Button>
                </div>
                {/* {savedUser !== '' ? (
                  <div className='col-sm-4'>
                    <br />
                    <Button variant='secondary' onClick={onSignTap}>
                      Teken mandaat
                    </Button>
                  </div>
                ) : null} */}
              </div>
            ) : (
              <Spinner animation='border' variant='warning' />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Signature;
