import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { /*RoomType,*/ RoomType, Stage } from '../../enums';
import { User } from '../../interfaces/user';

export type Session = {
  stage: Stage;
  user: User | null;
  test: string;
  backgroundImage: string;
  stagePhotoCount?: number;
  fireEffect?: boolean;
};

const initialState: Session = {
  stage: Stage.Intro,

  user: {
    name: '',
    defects: [
      {
        defectDescription: 'Is jy bewus van enige defekte aan die dak?',
      },
      {
        defectDescription:
          'Is jy bewus van enige defekte aan die elektriesestelsel?',
      },
      {
        defectDescription:
          'Is jy bewus van enige defekte aan die loodgieterstelsel, insluitend die swembad (indien van toepassing)?',
      },
      {
        defectDescription:
          'Is jy bewus van defekte aan die verwarming en lugversorgingstelsels, insluitende die lugfilters en lugbevogtigers?',
      },
      {
        defectDescription:
          'Is jy bewus van enige defekte aan die septiese, of ander rioolstelsels?',
      },
      {
        defectDescription:
          'Is jy bewus van enige defekte aan die eiendom en/of in die kelder of fondamente van die eiendom, insluitende krake, sypeling en bulte. Ander sulke defekte sluit in, maar is nie beperk nie tot, oorstromings, vogtigheid of nat mure en onveilige konsentrasies van skimmel of defekte in dreineringsteelwerk of opvangpompe?',
      },
      {
        defectDescription:
          'Is jy bewus van strukturele defekte aan die Eiendom?',
      },
      {
        defectDescription:
          'Is jy bewus van enige grenslyn geskille, oortredings of beswaring in verband met die Eiendom?',
      },
      {
        defectDescription:
          'Is jy bewus daarvan dat hermodellering en opknapping die struktuur van die Eiendom beïnvloed het?',
      },
      {
        defectDescription:
          'Is jy bewus daarvan dat enige toevoegings, of verbeterings aan, of enige oprigtings, wat op die eiendom aangebring is, gedoen of aangebring is, sonder dat die vereiste toestemmings en permitte om dit te doen, behoorlik verkry is?',
      },
      {
        defectDescription:
          'En laastens, is jy bewus daarvan dat ‘n struktuur op die eiendom as ‘n historiese struktuur of ‘n erfenis terrein geoormerk is?',
      },
    ],
    house: [
      {
        question: 'Voltitel, duet, deeltitel, kleinhoewe of plaas?',
      },
      { question: 'Hoeveel is die maandelikse erfbelasting?' },
      {
        question: 'Hoeveel is die maandelikse heffings, indien enige?',
      },
      { question: 'Wat is die vloer area van die huis?' },
      { question: 'Mag daar troeteldiere aangehou word?' },
      {
        question: 'Hoeveel slaapkamers het die huis?',
        roomsQuestion: true,
        roomType: RoomType.Bedrooms,
      },
      {
        question: 'Hoeveel badkamers het die huis?',
        roomsQuestion: true,
        roomType: RoomType.Bathrooms,
      },
      {
        question: 'Hoeveel studeerkamers het die huis?',
        roomsQuestion: true,
        roomType: RoomType.Study,
      },
      {
        question: 'Hoeveel leefkamers het die huis?',
        roomsQuestion: true,
        roomType: RoomType.LivingRooms,
      },
      {
        question: 'Hoeveel kombuise het die huis?',
        roomsQuestion: true,
        roomType: RoomType.Kitchen,
      },
      {
        question: 'Hoeveel opwaskamers het die huis?',
        roomsQuestion: true,
        roomType: RoomType.Laundry,
      },
      {
        question: 'Hoeveel stoepe het die huis?',
        roomsQuestion: true,
        roomType: RoomType.Porch,
      },
      {
        question: 'Hoeveel balkonne het die huis?',
        roomsQuestion: true,
        roomType: RoomType.Balcony,
      },
      {
        question: 'Hoeveel motorhuise het die huis?',
        roomsQuestion: true,
        roomType: RoomType.Garage,
      },
      {
        question: 'Hoeveel motorafdakke het die huis?',
        roomsQuestion: true,
        roomType: RoomType.MotorShade,
      },
      { question: 'Het die huis ‘n aparte woonstel?' },
      {
        question: 'Hoeveel bediendekamers het die huis?',
        roomsQuestion: true,
        roomType: RoomType.MaidenRoom,
      },
      { question: 'Watter tipe dak het die huis?' },
      {
        question: 'Watter afwerking het die buitekant van die huis?',
      },
      {
        question: 'Watter vloerafwerking is binne in die huis?',
      },
      { question: 'Het die huis ‘n swembad?' },
      { question: 'Is daar ‘n uitsig?' },
      { question: 'Watse omheining het die huis?' },
      {
        question: 'TUIN BOODSKAP',
        roomsQuestion: true,
        roomType: RoomType.Garden,
      },
    ],
    surname: '',
    idnumber: '',
    cellphone: '',
    email: '',
    address: '',
    price: '',
    bankvaluation: '',
  },

  // user: {
  //   name: 'Erhard',
  //   defects: [
  //     {
  //       defectDescription: 'Is jy bewus van enige defekte aan die dak?',
  //       defectYesNo: 'nee',
  //     },
  //     {
  //       defectDescription:
  //         'Is jy bewus van enige defekte aan die elektriesestelsel?',
  //       defectYesNo: 'nee',
  //     },
  //     {
  //       defectDescription:
  //         'Is jy bewus van enige defekte aan die loodgieterstelsel, insluitend die swembad (indien van toepassing)?',
  //       defectYesNo: 'ja',
  //       defectExplained: 'die kak snink',
  //     },
  //     {
  //       defectDescription:
  //         'Is jy bewus van defekte aan die verwarming en lugversorgingstelsels, insluitende die lugfilters en lugbevogtigers?',
  //       defectYesNo: 'nee',
  //     },
  //     {
  //       defectDescription:
  //         'Is jy bewus van enige defekte aan die septiese, of ander rioolstelsels?',
  //       defectYesNo: 'nee',
  //     },
  //     {
  //       defectDescription:
  //         'Is jy bewus van enige defekte aan die eiendom en/of in die kelder of fondamente van die eiendom, insluitende krake, sypeling en bulte. Ander sulke defekte sluit in, maar is nie beperk nie tot, oorstromings, vogtigheid of nat mure en onveilige konsentrasies van skimmel of defekte in dreineringsteelwerk of opvangpompe?',
  //       defectYesNo: 'nee',
  //     },
  //     {
  //       defectDescription:
  //         'Is jy bewus van strukturele defekte aan die Eiendom?',
  //       defectYesNo: 'nee',
  //     },
  //     {
  //       defectDescription:
  //         'Is jy bewus van enige grenslyn geskille, oortredings of beswaring in verband met die Eiendom?',
  //       defectYesNo: 'nee',
  //     },
  //     {
  //       defectDescription:
  //         'Is jy bewus daarvan dat hermodellering en opknapping die struktuur van die Eiendom beïnvloed het?',
  //       defectYesNo: 'nee',
  //     },
  //     {
  //       defectDescription:
  //         'Is jy bewus daarvan dat enige toevoegings, of verbeterings aan, of enige oprigtings, wat op die eiendom aangebring is, gedoen of aangebring is, sonder dat die vereiste toestemmings en permitte om dit te doen, behoorlik verkry is?',
  //       defectYesNo: 'nee',
  //     },
  //     {
  //       defectDescription:
  //         'En laastens, is jy bewus daarvan dat ‘n struktuur op die eiendom as ‘n historiese struktuur of ‘n erfenis terrein geoormerk is?',
  //       defectYesNo: 'nee',
  //     },
  //   ],
  //   house: [
  //     {
  //       question: 'Voltitel, duet, deeltitel, kleinhoewe of plaas?',
  //       answer: 'duet',
  //     },
  //     { question: 'Hoeveel is die maandelikse erfbelasting?', answer: '1' },
  //     {
  //       question: 'Hoeveel is die maandelikse heffings, indien enige?',
  //       answer: '2',
  //     },
  //     { question: 'Wat is die vloer area van die huis?', answer: '200' },
  //     { question: 'Mag daar troeteldiere aangehou word?', answer: 'ja' },
  //     {
  //       question: 'Hoeveel slaapkamers het die huis?',
  //       roomsQuestion: true,
  //       roomType: 'Bedrooms',
  //       answer: '2',
  //     },
  //     {
  //       question: 'Hoeveel badkamers het die huis?',
  //       roomsQuestion: true,
  //       roomType: 'Bathrooms',
  //       answer: '1',
  //     },
  //     {
  //       question: 'Hoeveel studeerkamers het die huis?',
  //       roomsQuestion: true,
  //       roomType: 'Study',
  //       answer: '1',
  //     },
  //     {
  //       question: 'Hoeveel leefkamers het die huis?',
  //       roomsQuestion: true,
  //       roomType: 'LivingRooms',
  //       answer: '1',
  //     },
  //     {
  //       question: 'Hoeveel kombuise het die huis?',
  //       roomsQuestion: true,
  //       roomType: 'Kitchen',
  //       answer: '0',
  //     },
  //     {
  //       question: 'Hoeveel opwaskamers het die huis?',
  //       roomsQuestion: true,
  //       roomType: 'Laundry',
  //       answer: '1',
  //     },
  //     {
  //       question: 'Hoeveel stoepe het die huis?',
  //       roomsQuestion: true,
  //       roomType: 'Porch',
  //       answer: '0',
  //     },
  //     {
  //       question: 'Hoeveel balkonne het die huis?',
  //       roomsQuestion: true,
  //       roomType: 'Balcony',
  //       answer: '0',
  //     },
  //     {
  //       question: 'Hoeveel motorhuise het die huis?',
  //       roomsQuestion: true,
  //       roomType: 'Garage',
  //       answer: '2',
  //     },
  //     {
  //       question: 'Hoeveel motorafdakke het die huis?',
  //       roomsQuestion: true,
  //       roomType: 'MotorShade',
  //       answer: '0',
  //     },
  //     { question: 'Het die huis ‘n aparte woonstel?', answer: 'nee' },
  //     {
  //       question: 'Hoeveel bediendekamers het die huis?',
  //       roomsQuestion: true,
  //       roomType: 'MaidenRoom',
  //       answer: '1',
  //     },
  //     { question: 'Watter tipe dak het die huis?', answer: 'teel' },
  //     {
  //       question: 'Watter afwerking het die buitekant van die huis?',
  //       answer: 'teel',
  //     },
  //     {
  //       question: 'Watter vloerafwerking is binne in die huis?',
  //       answer: 'teel',
  //     },
  //     { question: 'Het die huis ‘n swembad?' },
  //     { question: 'Is daar ‘n uitsig?' },
  //     { question: 'Watse omheining het die huis?' },
  //     { question: 'TUIN BOODSKAP', roomsQuestion: true, roomType: 'Garden' },
  //   ],
  //   surname: 'Smit',
  //   idnumber: '8005055099080',
  //   cellphone: '0836564309',
  //   email: 'e@e.com',
  //   address: '663 Silver Place ',
  //   price: '2000000.00',
  //   bankvaluation: 'nee',
  // },
  test: 'default',
  backgroundImage: 'char1.png',
  stagePhotoCount: 0,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    setStage: (state, action: PayloadAction<Stage>) => {
      // console.log('setStage', action.payload);
      state.stage = action.payload;
    },
    setTest: (state, action: PayloadAction<string>) => {
      state.test = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      // console.log("setUser", JSON.stringify(action.payload));
      state.user = action.payload;
    },
    setBackgroundImage: (state, action: PayloadAction<string>) => {
      state.backgroundImage = action.payload;
    },
    setStagePhotoCount: (state, action: PayloadAction<number>) => {
      state.stagePhotoCount = action.payload;
    },
    setFireEffect: (state, action: PayloadAction<boolean>) => {
      state.fireEffect = action.payload;
    },
  },
});

export const {
  setTest,
  setStage,
  setUser,
  setBackgroundImage,
  setStagePhotoCount,
  setFireEffect,
} = sessionSlice.actions;
export default sessionSlice.reducer;
