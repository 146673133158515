export const images = {
  logo: require('./images/logo.png'),

  char1: require('./images/char1.png'),
  char2: require('./images/char2.png'),
  char3: require('./images/char3.png'),
  char4: require('./images/char4.png'),
  char5: require('./images/char5.png'),
  char6: require('./images/char6.png'),
  char7: require('./images/char7.png'),
};
