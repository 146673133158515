import { createChatBotMessage } from 'react-chatbot-kit';
import NextButton from '../custom-messages/nextButton';
import Camera from '../widgets/Camera';

import Mandate from '../widgets/Mandate';
import Signature from '../widgets/Signature';
import { DELAYS } from './delays';
import BotAvatar from '../widgets/BotAvatar';
import Share from '../widgets/Share';

const botName = 'Oom Callie';

const config = {
  initialMessages: [
    // createChatBotMessage('test Signature,', { widget: 'signature' }),
    createChatBotMessage('Hallo,', { widget: 'CharWelcoming' }),
    createChatBotMessage('Ek is Oom Thys!', { delay: DELAYS.DELAY89 }),
    createChatBotMessage(
      'Indien jy nie nou tyd het om hierna te kyk nie, gaan besoek ons gerus op www.lysjouhuis.co.za om toegang te verkry.',
      {
        delay: DELAYS.DELAY90,
      }
    ),

    createChatBotMessage('Wat is jou voornaam?', {
      delay: DELAYS.DELAY91,
    }),
  ],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
  customMessages: {
    nextButton: (props) => <NextButton {...props} />,
  },
  customComponents: {
    botAvatar: (props) => <BotAvatar {...props} />,
  },
  widgets: [
    // {
    //   widgetName: 'character',
    //   widgetFunc: (props) => <DogPicture {...props} />,
    // },
    {
      widgetName: 'camera',
      widgetFunc: (props) => <Camera {...props} />,
    },
    {
      widgetName: 'mandate',
      widgetFunc: (props) => <Mandate {...props} />,
    },
    {
      widgetName: 'signature',
      widgetFunc: (props) => <Signature {...props} />,
    },
    {
      widgetName: 'share',
      widgetFunc: (props) => <Share {...props} />,
    },
  ],
};

export default config;
