/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum DELAYS {
  DELAY1 = 1000,
  DELAY2 = 4000,
  DELAY3 = 1000,
  DELAY4 = 1000,
  DELAY5 = 3000,
  DELAY6 = 1000,
  DELAY7 = 3000,
  DELAY8 = 1000,
  DELAY9 = 4000,
  DELAY10 = 1000,
  DELAY11 = 1000,
  DELAY12 = 4000,
  DELAY13 = 1000,
  DELAY14 = 1000,
  DELAY15 = 3000,
  DELAY16 = 1000,
  DELAY17 = 1000,
  DELAY18 = 5000,
  DELAY19 = 1000,
  DELAY20 = 1000,
  DELAY21 = 5000,
  DELAY22 = 1000,
  DELAY23 = 1000,
  DELAY24 = 1000,
  DELAY25 = 1000,
  DELAY26 = 7000,
  DELAY27 = 6000,
  DELAY28 = 8000,
  DELAY29 = 10500,
  DELAY30 = 13500,
  DELAY31 = 2500,
  DELAY32 = 6500,
  DELAY33 = 1000,
  DELAY34 = 4000,
  DELAY35 = 1000,
  DELAY36 = 1000,
  DELAY37 = 1000,
  DELAY38 = 1000,
  DELAY39 = 1000,
  DELAY40 = 2000,
  DELAY41 = 1000,
  DELAY42 = 3000,
  DELAY43 = 1000, // Let wel as jy n gratis bankwaardasie soek
  DELAY44 = 5500, // sal jy hiering belangstel
  DELAY45 = 1000,
  DELAY46 = 2000, // Geen probleem
  DELAY47 = 2000, // Na gelang
  DELAY48 = 2000, // die lysprys is
  DELAY49 = 2000, // Ons kommissie is
  DELAY50 = 2000, // Die prys wat jy dan
  DELAY51 = 2000, // reg, ons is amper klaar
  DELAY52 = 2000, // daar is nog een dokument
  DELAY53 = 2000, // dit gaan oor die defekte
  DELAY54 = 2000, // antwoord asseblief
  DELAY55 = 2000, // Defekte delay
  DELAY56 = 1000,
  DELAY57 = 1000,
  DELAY58 = 1000,
  DELAY59 = 3000,
  DELAY60 = 5000,
  DELAY61 = 7000,
  DELAY62 = 9000, // Voltitel delay
  DELAY63 = 1000,
  DELAY64 = 1000,
  DELAY65 = 1000,
  DELAY66 = 3500,
  DELAY67 = 6000,
  DELAY68 = 8500,
  DELAY69 = 11000,
  DELAY70 = 13500,
  DELAY71 = 16000,
  DELAY72 = 18500,
  DELAY73 = 21000,
  DELAY74 = 23500,
  DELAY75 = 26000,
  DELAY76 = 1000,
  DELAY77 = 1000,
  DELAY78 = 3000,
  DELAY79 = 5000,
  DELAY80 = 1000,
  DELAY81 = 1000,
  DELAY82 = 1000,
  DELAY83 = 1000,
  DELAY84 = 3000,
  DELAY85 = 5500,
  DELAY86 = 10000,
  DELAY87 = 1000,
  DELAY88 = 1000,
  DELAY89 = 2000,
  DELAY90 = 4000,
  DELAY91 = 9000,
  DELAY92 = 1000,
  DELAY93 = 1000,
  DELAY94 = 1000,
  DELAY95 = 1000,
  DELAY96 = 1000,
  DELAY97 = 1000,
  DELAY98 = 4500,
}
