export enum RoomType {
  Front = 'Front',
  Bedrooms = 'Bedrooms',
  Bathrooms = 'Bathrooms',
  Study = 'Study',
  LivingRooms = 'LivingRooms',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  BedroomsOther = 'Bedrooms',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  BathroomsOther = 'Bathrooms',
  Kitchen = 'Kitchen',
  Laundry = 'Laundry',
  Porch = 'Porch',
  Balcony = 'Balcony',
  Garage = 'Garage',
  MotorShade = 'MotorShade',
  MaidenRoom = 'MaidenRoom',
  Garden = 'Garden',
  NoPhoto = 'NoPhoto',
}

export enum Stage {
  Intro = 0,
  NameCaptured = 1,
  Popi = 2,
  PopiApproved = 3,
  PopiDeclined = 4,
  SurnameCaptured = 5,
  IDCaptured = 6,
  CellphoneCaptured = 70,
  EmailCaptured = 7,
  AddressCaptured = 8,
  PriceCaptured = 9,
  BankValuation = 10,
  Defect1Captured = 11,
  Defect2Captured = 12,
  Defect3Captured = 13,
  Defect4Captured = 14,
  Defect5Captured = 15,
  Defect6Captured = 16,
  Defect7Captured = 17,
  Defect8Captured = 18,
  Defect9Captured = 19,
  Defect10Captured = 20,
  Defect11Captured = 21,
  House1Captured = 22,
  House2Captured = 23,
  House3Captured = 24,
  House4Captured = 25,
  House5Captured = 26,
  House6Captured = 27,
  House7Captured = 28,
  House8Captured = 29,
  House9Captured = 30,
  House10Captured = 31,
  House11Captured = 32,
  House12Captured = 33,
  House13Captured = 34,
  House14Captured = 35,
  House15Captured = 36,
  House16Captured = 37,
  House17Captured = 38,
  House18Captured = 39,
  House19Captured = 40,
  House20Captured = 41,
  House21Captured = 42,
  House22Captured = 43,
  House23Captured = 44,
  PhotoFrontPage = 45,
  PhotoKitchen = 46,
  PhotoMainBedroom = 47,
  PhotoMainBathroom = 48,
  PhotoLivingRoom = 49,
  PhotoBedroom = 50,
  PhotoBathroom = 51,
  PhotoPorch = 52,
  PhotoBalcony = 53,
  PhotoGarden = 54,
  ListInEnglish = 55,
  SignMandate = 56,
  SignedMandate = 57,
  Done = 60,

  // NonFotoRoom = 100,
  // Foto1Captured,
  // Foto2Captured,
  // Foto3Captured,
  // Foto4Captured,
  // Foto5Captured,
}
