import axios, { AxiosResponse } from 'axios';
import { Config } from '../Config';
import { Flex } from '../interfaces/flex';

export const flexInsert = (flex: Flex) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Config.API_SERVER}/flex`,
      data: flex,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexUpdate = (flex: Flex) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'put',
      url: `${Config.API_SERVER}/flex`,
      data: flex,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexGetClosest = (flex: Flex) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Config.API_SERVER}/flex/get-closest`,
      data: flex,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexGetOne = (collection: string, id: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url: `${Config.API_SERVER}/flex/get/` + collection + '/' + id,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexGetAll = (flex: Flex) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Config.API_SERVER}/flex/get/` + flex.collection,
      data: flex,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexGeneric = (method: string, flex: Flex | null) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Config.API_SERVER}/flex/` + method,
      data: flex,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexDelete = (flex: Flex) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'delete',
      url: `${Config.API_SERVER}/flex/${flex.collection}/${flex._id}`,
      data: flex,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexGetTimeSheetCount = (userid: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url: `${Config.API_SERVER}/flex/get-timesheet-count/` + userid,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexGetCoachPersonalTransactions = (
  userid: string,
  completed: boolean
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url:
        `${Config.API_SERVER}/flex/get-personal-transactions/` +
        userid +
        '/' +
        completed,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexGetCoachOrgTransactions = (userid: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url: `${Config.API_SERVER}/flex/get-org-transactions/` + userid,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexGetCoachTransactions = (userid: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url: `${Config.API_SERVER}/flex/get-transactions/` + userid,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexGetCoachAllCompletedTransactions = (userid: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url: `${Config.API_SERVER}/flex/get-all-completed-transactions/` + userid,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexGetCoachUnCompletedTransactions = (coachid: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url:
        `${Config.API_SERVER}/flex/get-coach-uncompleted-transactions/` +
        coachid,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const flexGetCoachAvgRating = (coachid: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url: `${Config.API_SERVER}/flex/get-avg-rating/` + coachid,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};
