import React from 'react';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import { Stage } from '../enums';

enum InputType {
  YesNo,
  Name,
  Mobile,
  Email,
  ID,
  Address,
  Price,
  PriceNoMin,
  StandType,
  Sqm,
  Rooms,
}

const MessageParser = ({ children, actions }: any) => {
  const session = useSelector((state: RootState) => state.session);

  const emailValidation = (email: string) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      return false;
    }

    return true;
  };

  const numberValidation = (myNumber: string) => {
    const numberRegEx = /\-?\d*\.?\d{1,2}/;

    return numberRegEx.test(String(myNumber).toLowerCase());
  };

  const validateTextInput = (input: string, type: InputType) => {
    switch (type) {
      case InputType.YesNo: {
        if (
          input.trim().toUpperCase() === 'YES' ||
          input.trim().toUpperCase() === 'JA' ||
          input.trim().toUpperCase() === 'NO' ||
          input.trim().toUpperCase() === 'NEE' ||
          input.trim().toUpperCase() === 'NOPE'
        )
          return '';
        else return "Ons verwag net 'n ja/nee antwoord hier asseblief.";
      }
      case InputType.Email: {
        if (!emailValidation(input))
          return 'Jou e-pos adres lyk nie reg nie. Tik dit asb oor en maak seker die e-pos formaat korek is.';
        else return '';
      }
      case InputType.Name: {
        if (input.trim().length < 2)
          return 'Jou naam en van moet ten minste 2 karakters lank wees.';
        else return '';
      }
      case InputType.Address: {
        if (input.trim().length < 10)
          return 'Jou adres lyk nie korek nie, maak asb seker om die adres volledig uit te skryf insluitend die stad/dorp, provinsie en die kode.';
        else return '';
      }
      case InputType.ID: {
        if (!numberValidation(input) || input.trim().length !== 13)
          return 'Jou ID nommer lyk nie korek nie. Maak asb seker dat jou ID nommer reg is.';
        else return '';
      }
      case InputType.Mobile: {
        if (!numberValidation(input) || input.trim().length !== 10)
          return 'Jou selfoonnommer lyk nie korek nie. Maak asb seker dat jou selfoonnommer nommer reg is.';
        else return '';
      }
      case InputType.Price: {
        if (
          !numberValidation(input.replaceAll(' ', '').replaceAll('R', '')) ||
          Number(input.replaceAll(' ', '').replaceAll('R', '')) < 250000
        )
          return 'Die prys is nie reg nie en kan nie minder as R 250000 wees nie.';
        else return '';
      }
      case InputType.PriceNoMin: {
        if (
          !numberValidation(input.replaceAll(' ', '').replaceAll('R', '')) ||
          Number(input.replaceAll(' ', '').replaceAll('R', '')) < 0
        )
          return 'Die prys is nie reg nie en kan nie minder as R 0 wees nie.';
        else return '';
      }
      case InputType.Sqm: {
        if (
          !numberValidation(input.replaceAll(' ', '').replaceAll('R', '')) ||
          Number(
            input.replaceAll(' ', '').replaceAll('m2', '').replaceAll('sqm', '')
          ) < 50
        )
          return 'Die oppervlakte lyk nie reg nie, dit moet ten minste meer as 50m2 wees.';
        else return '';
      }
      case InputType.Rooms: {
        if (
          !numberValidation(input.replaceAll(' ', '').replaceAll('R', '')) ||
          Number(
            input
              .replaceAll(' ', '')
              .replaceAll('room', '')
              .replaceAll('rooms', '')
          ) < 0 ||
          Number(
            input
              .replaceAll(' ', '')
              .replaceAll('room', '')
              .replaceAll('rooms', '')
          ) > 20
        )
          return "Die nommer lyk nie reg nie. Tik asb 'n syfer tussen 0 en 20 in.";
        else return '';
      }
      case InputType.StandType: {
        if (
          input.trim().toUpperCase() === 'VOLTITEL' ||
          input.trim().toUpperCase() === 'DUET' ||
          input.trim().toUpperCase() === 'DEELTITEL' ||
          input.trim().toUpperCase() === 'KLEINHOEWE' ||
          input.trim().toUpperCase() === 'PLAAS'
        )
          return '';
        else
          return 'Tik asb slegs een van die volgende: Voltitel, duet, deeltitel, kleinhoew of plaas.';
      }
      default:
        return '';
    }
  };

  const parse = (message: any) => {
    switch (session.stage) {
      case Stage.Intro: {
        const result = validateTextInput(message, InputType.Name);
        if (result === '') {
          actions.handleNameCaptured(message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.Popi: {
        const result = validateTextInput(message, InputType.YesNo);
        if (result === '') {
          if (
            message.toUpperCase().includes('JA') ||
            message.toUpperCase().includes('YES')
          ) {
            actions.handlePopiApproved();
          } else {
            actions.handlePopiDeclined();
          }
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.PopiApproved: {
        const result = validateTextInput(message, InputType.Name);
        if (result === '') {
          actions.handleSurnameCaptured(message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.SurnameCaptured: {
        const result = validateTextInput(message, InputType.ID);
        if (result === '') {
          actions.handleIDCaptured(message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.IDCaptured: {
        const result = validateTextInput(message, InputType.Mobile);
        if (result === '') {
          actions.handleCellphoneCaptured(message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.CellphoneCaptured: {
        const result = validateTextInput(message, InputType.Email);
        if (result === '') {
          actions.handleEmailCaptured(message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.EmailCaptured: {
        const result = validateTextInput(message, InputType.Address);
        if (result === '') {
          actions.handleAddressCaptured(message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.AddressCaptured: {
        const result = validateTextInput(message, InputType.Price);
        if (result === '') {
          actions.handlePriceCaptured(message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.PriceCaptured: {
        const result = validateTextInput(message, InputType.YesNo);
        if (result === '') {
          actions.handleBankValuationCaptured(message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.BankValuation: {
        actions.handleDefectCaptured(1, message);
        break;
      }
      case Stage.Defect1Captured: {
        actions.handleDefectCaptured(2, message);
        break;
      }
      case Stage.Defect2Captured: {
        actions.handleDefectCaptured(3, message);
        break;
      }
      case Stage.Defect3Captured: {
        actions.handleDefectCaptured(4, message);
        break;
      }
      case Stage.Defect4Captured: {
        actions.handleDefectCaptured(5, message);
        break;
      }
      case Stage.Defect5Captured: {
        actions.handleDefectCaptured(6, message);
        break;
      }
      case Stage.Defect6Captured: {
        actions.handleDefectCaptured(7, message);
        break;
      }
      case Stage.Defect7Captured: {
        actions.handleDefectCaptured(8, message);
        break;
      }
      case Stage.Defect8Captured: {
        actions.handleDefectCaptured(9, message);
        break;
      }
      case Stage.Defect9Captured: {
        actions.handleDefectCaptured(10, message);
        break;
      }
      case Stage.Defect10Captured: {
        actions.handleDefectCaptured(11, message);
        break;
      }
      case Stage.Defect11Captured: {
        const result = validateTextInput(message, InputType.StandType);
        if (result === '') {
          actions.handleQuestionCaptured(1, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House1Captured: {
        const result = validateTextInput(message, InputType.PriceNoMin);
        if (result === '') {
          actions.handleQuestionCaptured(2, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House2Captured: {
        const result = validateTextInput(message, InputType.PriceNoMin);
        if (result === '') {
          actions.handleQuestionCaptured(3, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House3Captured: {
        const result = validateTextInput(message, InputType.Sqm);
        if (result === '') {
          actions.handleQuestionCaptured(4, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House4Captured: {
        const result = validateTextInput(message, InputType.YesNo);
        if (result === '') {
          actions.handleQuestionCaptured(5, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House5Captured: {
        const result =
          Number(message ?? '0') === 0
            ? 'Daar moet ten minste 1 slaap kamer wees.'
            : validateTextInput(message, InputType.Rooms);
        if (result === '') {
          actions.handleQuestionCaptured(6, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House6Captured: {
        const result =
          Number(message ?? '0') === 0
            ? 'Daar moet ten minste 1 badkamer wees.'
            : validateTextInput(message, InputType.Rooms);
        if (result === '') {
          actions.handleQuestionCaptured(7, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House7Captured: {
        const result = validateTextInput(message, InputType.Rooms);
        if (result === '') {
          actions.handleQuestionCaptured(8, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House8Captured: {
        const result = validateTextInput(message, InputType.Rooms);
        if (result === '') {
          actions.handleQuestionCaptured(9, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House9Captured: {
        const result = validateTextInput(message, InputType.Rooms);
        if (result === '') {
          actions.handleQuestionCaptured(10, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House10Captured: {
        const result = validateTextInput(message, InputType.Rooms);
        if (result === '') {
          actions.handleQuestionCaptured(11, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House11Captured: {
        const result = validateTextInput(message, InputType.Rooms);
        if (result === '') {
          actions.handleQuestionCaptured(12, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House12Captured: {
        const result = validateTextInput(message, InputType.Rooms);
        if (result === '') {
          actions.handleQuestionCaptured(13, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House13Captured: {
        const result = validateTextInput(message, InputType.Rooms);
        if (result === '') {
          actions.handleQuestionCaptured(14, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House14Captured: {
        const result = validateTextInput(message, InputType.Rooms);
        if (result === '') {
          actions.handleQuestionCaptured(15, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House15Captured: {
        const result = validateTextInput(message, InputType.YesNo);
        if (result === '') {
          actions.handleQuestionCaptured(16, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House16Captured: {
        const result = validateTextInput(message, InputType.Rooms);
        if (result === '') {
          actions.handleQuestionCaptured(17, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House17Captured: {
        actions.handleQuestionCaptured(18, message);
        break;
      }
      case Stage.House18Captured: {
        actions.handleQuestionCaptured(19, message);
        break;
      }
      case Stage.House19Captured: {
        actions.handleQuestionCaptured(20, message);
        break;
      }
      case Stage.House20Captured: {
        const result = validateTextInput(message, InputType.YesNo);
        if (result === '') {
          actions.handleQuestionCaptured(21, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House21Captured: {
        const result = validateTextInput(message, InputType.YesNo);
        if (result === '') {
          actions.handleQuestionCaptured(22, message);
        } else {
          actions.handleCustomMessage(result);
        }
        break;
      }
      case Stage.House22Captured: {
        actions.handleQuestionCaptured(23, message);
        break;
      }
      case Stage.House23Captured: {
        actions.handleQuestionCaptured(24, message);
        break;
      }
      case Stage.PhotoBalcony: {
        if (
          message.toUpperCase().includes('JA') ||
          message.toUpperCase().includes('YES')
        ) {
          actions.handleGardenPhotos(true);
        } else {
          actions.handleGardenPhotos(false);
        }
        break;
      }
      case Stage.ListInEnglish: {
        actions.handleListInEnglish(message);
        break;
      }
      case Stage.SignedMandate: {
        if (
          message.toUpperCase().includes('JA') ||
          message.toUpperCase().includes('YES')
        ) {
          actions.handleShare(true);
        } else {
          actions.handleShare(false);
        }
        break;
      }
    }
  };

  return (
    <div>
      {React.Children.map(children, (child: any) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: {},
        });
      })}
    </div>
  );
};

export default MessageParser;
